import { Fragment, useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import { Button, Table, notification, Tag } from "antd";
import { TextDescriptionCompanies } from "components";
//import { useSecurity } from "../../../../../../config/hooks";
import { ComplexSelect } from "../../../../../../components";
import { searchAnnouncements } from "../../../companies/modals/sendEmail/redux/slice";
import {
    getAnnouncementsToExport,
    reset,
    saveCSVReport,
    searchAnnouncementsReportLogs,
} from "./redux/slice";
import { usePaggination } from "../../../../../../config/hooks";

export const ReportCSVListView = () => {
    const dispatch = useDispatch();
    const { messages } = useIntl();
    const { announcements, loadingAnnouncements } = useSelector(
        (state) => state.companiesSendEmailRedux
    );
    const {
        announcementsToExport,
        announcementsLogs,
        loadingAnnouncementLogs,
        error,
        result,
        loading,
    } = useSelector((state) => state.repotCSVRedux);

    const pagginationAnnouncementToExport = usePaggination(
        getAnnouncementsToExport
    );
    const pagginationLogs = usePaggination(searchAnnouncementsReportLogs);

    const [idsAnnouncements, setIdsAnnouncements] = useState([]);
    const [currentAnnouncementsIds, setCurrentAnnouncementIds] = useState([]);

    useEffect(() => {
        pagginationLogs.refresh();
        return () => {
            dispatch(reset());
        };
    }, []);

    useEffect(() => {
        if (result) {
            notification.success({
                message: messages["saveSuccessfullyTitle"]?.toString(),
                description: messages["saveSuccessfully"]?.toString(),
            });
            reset("result");
        }
        if (error) {
            notification.error({
                message: messages["saveError"]?.toString(),
                description: messages[error]?.toString() || error,
            });
            reset("error");
        }
    }, [error, result]);

    const handleAnnouncementsIds = (values) => {
        setIdsAnnouncements(values);
    };

    const handleAnnouncementsToExport = () => {
        setCurrentAnnouncementIds(idsAnnouncements)
        pagginationAnnouncementToExport.refresh(
            1,
            null,
            { ids: idsAnnouncements.map((v) => v.value) },
            10
        );
    };

    const exportCsv = () => {
        dispatch(
            saveCSVReport({ ids: currentAnnouncementsIds.map((v) => v.value) })
        )
            .unwrap()
            .then(({ url, name }) => {
                // Abrir el archivo para que el usuario pueda descargarlo directamente
                const a = document.createElement("a");
                a.href = url;
                a.target = '_blank'
                a.rel = "noreferrer"
                a.download = name;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                // Liberar la URL
                window.URL.revokeObjectURL(url);
                //refresco de tabla
                pagginationLogs.refresh();
            });
    };

    return (
        <Fragment>
            <div className="container-fluid flex-grow-1 container-p-y">
                <div className="card card-body">
                    <div className="col-md-8 col-sm-12 mt-4 mb-4">
                        <TextDescriptionCompanies />
                    </div>

                    <div>
                        <ComplexSelect
                            labelPropName="product"
                            action={searchAnnouncements}
                            mode="multiple"
                            loading={loadingAnnouncements}
                            data={announcements}
                            value={idsAnnouncements}
                            onChange={handleAnnouncementsIds}
                        />
                        <div className="my-2 text-center">
                            <Button
                                className="btn btn-primary"
                                onClick={handleAnnouncementsToExport}
                                disabled={
                                    idsAnnouncements.length === 0 || loading
                                }
                            >
                                {announcementsToExport.length === 0
                                    ? messages["add"]
                                    : messages["edit"]}
                            </Button>
                        </div>
                    </div>
                    <div className="my-4 d-flex flex-column justify-content-center">
                        <h2 className="text-center">
                            {messages["reportCSV_haveAcquierd"]}
                        </h2>
                        <div className="my-2 text-center">
                            <Button
                                className="btn btn-primary"
                                onClick={exportCsv}
                                disabled={
                                    announcementsToExport.length === 0 ||
                                    loading
                                }
                                loading={loading}
                            >
                                {messages["reportCSV"]}
                            </Button>
                        </div>
                    </div>
                    <div className="mt-4 table-responsive">
                        <Table
                            rowKey="codiceRichiesta"
                            loading={loading}
                            dataSource={announcementsToExport || []}
                            columns={[
                                {
                                    title: messages["reportCSV_firstName"],
                                    dataIndex: "firstName",
                                    key: "firstName",
                                },
                                {
                                    title: messages["reportCSV_lastName"],
                                    dataIndex: "lastName",
                                    key: "lastName",
                                },
                                {
                                    title: messages["reportCSV_phone"],
                                    dataIndex: "phone",
                                    key: "phone",
                                },
                                {
                                    title: messages["reportCSV_email"],
                                    dataIndex: "email",
                                    key: "email",
                                },
                                {
                                    title: messages["reportCSV_tags"],
                                    dataIndex: "tags",
                                    key: "tags",
                                    render: (tags) => (
                                        <>
                                            {tags &&
                                                tags.map((tag) => (
                                                    <span
                                                        key={tag}
                                                        style={{
                                                            marginRight: 8,
                                                        }}
                                                    >
                                                        {tag}
                                                    </span>
                                                ))}
                                        </>
                                    ),
                                },
                                {
                                    title: messages["reportCSV_city"],
                                    dataIndex: "city",
                                    key: "city",
                                },
                                {
                                    title: messages["reportCSV_state"],
                                    dataIndex: "state",
                                    key: "state",
                                },
                                {
                                    title: messages["reportCSV_province"],
                                    dataIndex: "provincia",
                                    key: "provincia",
                                },
                                {
                                    title: messages["reportCSV_titleRequest"],
                                    dataIndex: "titoloRichiesta",
                                    key: "titoloRichiesta",
                                },
                                {
                                    title: messages["reportCSV_dataRequest"],
                                    dataIndex: "dataRichiesta",
                                    key: "dataRichiesta",
                                    render: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm')
                                },
                                {
                                    title: messages["reportCSV_categorie"],
                                    dataIndex: "categorie",
                                    key: "categorie",
                                },
                                {
                                    title: messages["reportCSV_description"],
                                    dataIndex: "descrizione",
                                    key: "descrizione",
                                },
                                {
                                    title: messages[
                                        "reportCSV_expectedProjectDate"
                                    ],
                                    dataIndex: "dataPrevistaProgetto",
                                    key: "dataPrevistaProgetto",
                                },
                                {
                                    title: messages["reportCSV_requestType"],
                                    dataIndex: "tipoRichiesta",
                                    key: "tipoRichiesta",
                                },
                                {
                                    title: messages["reportCSV_whereIsNeedit"],
                                    dataIndex: "doveGliServe",
                                    key: "doveGliServe",
                                },
                                {
                                    title: messages["reportCSV_searchthing"],
                                    dataIndex: "cosaStaCercando",
                                    key: "cosaStaCercando",
                                },
                                {
                                    title: messages["reportCSV_inspection"],
                                    dataIndex: "sopralluogo",
                                    key: "sopralluogo",
                                },
                                {
                                    title: messages["reportCSV_requestCode"],
                                    dataIndex: "codiceRichiesta",
                                    key: "codiceRichiesta",
                                },
                                {
                                    title: messages[
                                        "reportCSV_desingAndEvaluation"
                                    ],
                                    dataIndex: "progettazioneValutazione",
                                    key: "progettazioneValutazione",
                                },
                                {
                                    title: messages[
                                        "reportCSV_requestPermissions"
                                    ],
                                    dataIndex: "richiestaPermessi",
                                    key: "richiestaPermessi",
                                },
                                {
                                    title: messages[
                                        "reportCSV_receiveQuotations"
                                    ],
                                    dataIndex: "soloAziendeZona",
                                    key: "soloAziendeZona",
                                },
                            ]}
                            pagination={{
                                total: currentAnnouncementsIds?.length,
                                ...pagginationAnnouncementToExport.tablePaggination,
                            }}
                        />
                    </div>

                    <div className="my-4">
                        <h2 className="text-center">
                            {messages["reportCSV_actionsPerformed"]}
                        </h2>
                    </div>
                    <div className="mt-4 table-responsive">
                        <Table
                            rowKey="id"
                            loading={loadingAnnouncementLogs}
                            dataSource={announcementsLogs.items}
                            columns={[
                                {
                                    title: messages["user"],
                                    dataIndex: "user",
                                    key: "fullName",
                                    render: (value) => value?.fullName,
                                    width: 250
                                },
                                {
                                    title: 'Id annuncio',
                                    dataIndex: "announcement_ids",
                                    key: "id_elements",
                                    render: (value) => (
                                        <div style={{ width: '400px' }}>
                                            {value}
                                        </div>
                                    ),
                                    width: 400
                                },
                                {
                                    title: messages["httpLog_date"],
                                    key: "date",
                                    dataIndex: "date",
                                    width: 300,
                                    render: (row) =>
                                        moment(row).format(
                                            "DD/MM/yyyy, h:mm:ss a"
                                        ),
                                },
                                {
                                    title: "Type",
                                    key: "type",
                                    dataIndex: "type",
                                    width: 150,
                                    render: (row) => {
                                        switch (row) {
                                            case 'read':
                                                return <Tag color="blue">Lettura</Tag>;
                                            case 'export':
                                                return <Tag color="gold">Esportazione</Tag>
                                            default:
                                                return <></>;
                                        }
                                    },
                                },
                                {
                                    title: '.CSV',
                                    key: "download",
                                    dataIndex: "fileURL",
                                    render: (value) => (
                                        <>
                                            {value ? (
                                                <a
                                                    className="link-primary"
                                                    href={value}
                                                    target="_blank"
                                                    download
                                                    rel="noreferrer"
                                                >
                                                    Download
                                                </a>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ),
                                },
                            ]}
                            pagination={{
                                total: announcementsLogs?.count || 0,
                                ...pagginationLogs.tablePaggination,
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
