import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { resetALL } from "../../../../../../../config/redux/actions";
import { clientQuery, clientMutation } from "../../../../../../../config/helpers/GraphQLApi";
import { endpoints } from "../../../../../../../config/constants";
import { ExceptionManager } from "../../../../../../../config/helpers/Logging";

//STATE INITIAL
const INIT_STATE = {
  result: null,
  announcementsToExport: [],
  announcementsLogs: { count: 0, items: [] },
  loadingAnnouncementLogs: false,
  loading: false,
  error: null,
};

const route = "modules/management/reportCSV/views/list/redux/slice";



export const getAnnouncementsToExport = createAsyncThunk(
  "exportCSV/list/getAnnouncementsToExport",
  async (
    { ids = [], limit = 10, offset = 0 },
    { rejectWithValue, dispatch }
  ) => {
    if (ids.length < 1) {
      throw 'error'
    }
    try {
      let data = await clientQuery(
        `query getAnnouncementsToExport($ids: [Int]!, $limit: Int, $offset: Int) {
          result: getAnnouncementsToExport(ids: $ids, limit: $limit, offset: $offset) {
            firstName
            lastName
            phone
            email
            city
            state
            provincia
            titoloRichiesta
            dataRichiesta
            categorie
            descrizione
            dataPrevistaProgetto
            tipoRichiesta
            doveGliServe
            cosaStaCercando
            sopralluogo
            codiceRichiesta
            progettazioneValutazione
            richiestaPermessi
            soloAziendeZona
          }
        }`,
        {
          ids,
          limit,
          offset
        },
        endpoints.GRAPHQL_GENERAL
      );

      dispatch(searchAnnouncementsReportLogs({ limit: 10, offset: 0 }))
      return data?.result || null;
    } catch (exc) {
      ExceptionManager(exc, route, "getAnnouncementsToExport", dispatch);
      return rejectWithValue(exc);
    }
  }
);

export const searchAnnouncementsReportLogs = createAsyncThunk(
  "exportCSV/list/searchAnnouncementsReportLogs",
  async (
    { limit, offset },
    { rejectWithValue, dispatch }
  ) => {
    try {
      let data = await clientQuery(
        `query getAnnouncementReportLogsSearchable($limit: Int, $offset: Int) {
          result: getAnnouncementReportLogsSearchable(limit: $limit, offset: $offset) {
            count
            items {
              id
              userId
              origin
              type
              date
              fileURL
              user {
                fullName
                firstName
                lastName
              }
              announcement_ids
            }
          }
        }`,
        {
          limit,
          offset
        },
        endpoints.GRAPHQL_GENERAL
      );

      return data?.result;
    } catch (exc) {
      ExceptionManager(exc, route, "searchAnnouncementsReportLogs", dispatch);
      return rejectWithValue(exc);
    }
  }
);

export const saveCSVReport = createAsyncThunk(
  "exportCSV/list/saveCSVReport",
  async (
    { ids = [] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (ids.length < 1) {
        throw 'error'
      }
      let data = await clientMutation(
        `mutation makeAnnouncementReportCsv($ids: [Int]!) {
          result: makeAnnouncementReportCsv(ids: $ids) {
            url
            name
          }
        }`,
        {
          ids
        },
        endpoints.GRAPHQL_GENERAL
      );

      return data?.result;
    } catch (exc) {
      ExceptionManager(exc, route, "saveCSVReport", dispatch);
      return rejectWithValue(exc);
    }
  }
);
//Slice
export const exportCSVSlice = createSlice({
  name: "exportCSV/list",
  initialState: INIT_STATE,
  reducers: {
    reset(state, action) {
      return action.payload
        ? { ...state, ...action.payload }
        : { ...INIT_STATE };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetALL, () => INIT_STATE);

    // getDocumentsList
    builder.addCase(getAnnouncementsToExport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAnnouncementsToExport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.announcementsToExport = payload;
    });
    builder.addCase(getAnnouncementsToExport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });

    // searchAnnouncementsReportLogs
    builder.addCase(searchAnnouncementsReportLogs.pending, (state) => {
      state.loadingAnnouncementLogs = true;
    });
    builder.addCase(searchAnnouncementsReportLogs.fulfilled, (state, { payload }) => {
      state.loadingAnnouncementLogs = false;
      state.announcementsLogs = payload;
    });
    builder.addCase(searchAnnouncementsReportLogs.rejected, (state, action) => {
      state.loadingAnnouncementLogs = false;
      state.error = action.payload.error;
    });

    //saveCSVReport
    builder.addCase(saveCSVReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveCSVReport.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.result = payload;
    });
    builder.addCase(saveCSVReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
  },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = exportCSVSlice;

// Extract and export each action creator by name
export const { reset } = actions;

// Export the reducer, either as a default or named export
export default reducer;
